<template>
  <!-- <nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </nav> -->
  <router-view/>
</template>

<style lang="scss">
.btn-primary{
  background-color: var(--bs-primary);
  border-color: #d11104;
}
.btn-primary:hover{
  background-color: var(--bs-primary) !important;
  border-color: #d11104;
  color: white !important;
}
// .topbar{
//   background: #d11104 !important;
// }
.text-primary {
  color: var(--bs-primary) !important;
}

// .about .tab-class .nav .nav-item a.active, .about .tab-class .nav .nav-item a.active span {
//     background:#d11104 !important;
//     color: white !important;
// }
</style>
